import React, { useContext, useEffect } from "react";
import Footer from "./Footer";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import "../../assets/admin/css/styles.css";
import { useState } from "react";
import "../../Layouts/admin/Master.scss";
import axios from "axios";
import Routes from "../../routes/routes";
import logo from "../../assets/admin/assets/img/logo.svg";
import NotesIcon from "@mui/icons-material/Notes";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import { UserContext } from "../../Usecontext/UserContext";
import Swal from "sweetalert2";
import Alert from "../../assets/admin/assets/Music/Notification.mp3";
import CloseIcon from "@mui/icons-material/Close";
import Audio from "../../assets/admin/assets/Music/audio.mp3";
import "../../Layouts/admin/Master.scss";
import { ErrorMessage } from "../../Components/Customalert/Customalert";
const MasterLayout = () => {
  const { setapproval, setnotification, setalert } = useContext(UserContext);
  const { notification, notify_alert } = useContext(UserContext);
  const [notifylist, setnotifylist] = useState([]);
  const [orders, setorders] = useState(0);
  const [export_order, setexport_order] = useState({});

  // ....................Swal..........................
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  // ............................................
  const [btnstate, setbtnstate] = useState("false");

  const [belling, setbelling] = useState("false");

  const handlebell = () => {
    setbelling((belling) => !belling);
  };

  // ...........................................

  let toogle = belling ? "bell" : null;
  let toogleclass = btnstate ? "activate" : null;

  const handleclick = () => {
    setbtnstate((btnstate) => !btnstate);
  };

  // .....................logoutsubmit..........................

  function logoutsubmit(e) {
    e.preventDefault();
    try {
      axios.post("/admin/logout").then((res) => {
        if (res.data.statuscode === 200) {
          localStorage.removeItem("token");
          window.location = "/";
          localStorage.removeItem("id");
          localStorage.removeItem("name");
        } else if (res.data.status === 401) {
        }
      });
    } catch (error) {
      ErrorMessage({ message: "Something Went Wrong !" });
    }
  }
  // .........................................................

  const handlenotify = (e) => {
    e.preventDefault();
    setbelling((belling) => !belling);
    axios.post(`/notification_list`).then((res) => {
      if (res.data.status === true) {
        setnotifylist(res.data.NewNotificationList);
      }
    });
  };

  // .........................order-isloading.................................

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/admin/order-isloading");
        if (res.data.status === true) {
          setorders(res.data.new_order_count);
          setnotification(res.data.new_notification_count);
          setalert(res.data.new_alert_count);
          setexport_order(res.data.export_order_count);
          if (res.data.new_order_count > 0) {
            axios.get(`/admin/order-loading-update`).then((res) => {
              if (res.data.status === true) {
                return setorders;
              }
            });
            axios.get(`/admin/place-order-list`).then((res) => {
              if (res.data.status === true) {
                setapproval(res.data.OrderList);
              }
            });
          }
          if (res.data.export_order_count > 0) {
            axios.get(`/admin/export-order-loading-update`).then((res) => {
              if (res.data.status === true) {
                return setexport_order;
              }
            });
            axios.get(`/admin/place-order-list`).then((res) => {
              if (res.data.status === true) {
                setapproval(res.data.OrderList);
              }
            });
          }
        }
      } catch (error) {
        ErrorMessage({ message: "Something Went Wrong !" });
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 12000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="masterlayout">
      <div className={`sb-nav-fixed${toogleclass}`}>
        <nav className="sb-topnav navbar navbar-expand navbar-dark nav-bgcor">
          <Link to="/admin" className="navbar-brand ps-3 logo-font">
            <img src={logo}></img>
          </Link>
          <button
            className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
            onClick={handleclick}
          >
            <NotesIcon />
          </button>
          <div className="heading">
            <h5>Welcome!</h5>
            <h4>{localStorage.getItem("name")}</h4>
          </div>
          <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
            <div className="input-group"></div>
          </form>
          <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4 nav-icons">
            <li className="first-bell">
              <Link to="#">
                <NotificationsIcon className="ico" onClick={handlenotify} />
                {notification > 0 ? (
                  <small className="bell-icon">{notification}</small>
                ) : (
                  <small className="bell-icon">0</small>
                )}

                {orders > 0 ? (
                  <small className="bell-icon">{orders}</small>
                ) : (
                  <small className="bell-icon">0</small>
                )}
                {export_order > 0 ? (
                  <small className="bell-icon">{export_order}</small>
                ) : (
                  <small className="bell-icon">0</small>
                )}
              </Link>
              <div className={`second-bell${toogle}`}>
                <div className="cards">
                  <h4 className="head">
                    <span>Notification</span>
                    <Link>
                      <CloseIcon onClick={handlebell} />
                    </Link>
                  </h4>
                  <div className="childeren-nidify">
                    <ul>
                      {notifylist.length > 0 ? (
                        notifylist.map((item) => {
                          return (
                            <>
                              <li className={`${item.status}`}>{item.note}</li>
                            </>
                          );
                        })
                      ) : (
                        <small>No Notification!</small>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/admin/user" className="dropdown-item">
                <PersonIcon className="ico" />
              </Link>
            </li>
            <li>
              <button
                type="button"
                onClick={logoutsubmit}
                className="dropdown-item"
              >
                <LogoutIcon className="ico" />
              </button>
            </li>
          </ul>
        </nav>
        <div id="layoutSidenav">
          <div id="layoutSidenav_nav">
            <Sidebar />
          </div>
          <div id="layoutSidenav_content">
            <main>
              <Switch>
                {Routes.map((route, indx) => {
                  return (
                    route.component && (
                      <Route
                        key={indx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    )
                  );
                })}
                <Redirect to="/admin/dashboard" />
              </Switch>
            </main>
            <Footer />
          </div>
        </div>
        {notify_alert
          ? (Toast.fire({
              icon: "success",
              title: "New order has been arrived.",
            }),
            (<iframe src={Alert} allow="autoplay"></iframe>))
          : null}
        {orders > 0
          ? (Toast.fire({
              icon: "success",
              title: "New Import Order has been arrived.",
            }),
            (<iframe src={Audio} allow="autoplay"></iframe>))
          : null}
        {export_order > 0
          ? (Toast.fire({
              icon: "success",
              title: "New Export Order has been arrived.",
            }),
            (<iframe src={Audio} allow="autoplay"></iframe>))
          : null}
      </div>
    </section>
  );
};

export default MasterLayout;
