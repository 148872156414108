import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Layouts/frontend/auth/Login";
import Register from "./Layouts/frontend/auth/Register";
import axios from "axios";
import LoginRegister from "../src/Layouts/frontend/auth/LoginRegister";
import VendorMasterLayout from "../src/Layouts/vendor/vendorMasterLayout";
import MasterLayout from "../src/Layouts/admin/MasterLayout";
import ValueProvide from "./Components/admin/CommonApi/ValueProvide";
import Home from "../src/Layouts/frontend/Home";
import Privateroute from "./Privateroute";
// ..............All css..................
import "./Components/admin/admincard.css";
import "./Components/admin/Driver/driver.css";
import "./Components/admin/Feedback/feedback.css";
import "./Components/admin/Vendor/customer.css";
import "./Components/admin/Driver/driver.css";
import "./Components/admin/Vehicle/Vechile.css";
import "./Components/admin/Vehicle/AddVechile.css";
import "./Components/admin/Vehicle/Editvechile.css";
import "./Components/admin/User/User.css";
import "./Components/admin/order/order.css";
import "./Components/admin/Card-view/approval.css";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_KEY;
  axios.defaults.headers.post["Accept"] = "application/json";
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(function (config) {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      config.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    if (localStorage.getItem("vendors")) {
      const vendors = localStorage.getItem("vendors");
      config.headers.Authorization = vendors ? `Bearer ${vendors}` : "";
    }
    return config;
  });

  return (
    <div className="App">
        <React.StrictMode> 
        <ValueProvide>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/loginRegister" component={LoginRegister} />
              <Privateroute path="/admin" component={MasterLayout} />
              <Route path="/vendor" component={VendorMasterLayout} />
            </Switch>
          </Router>
        </ValueProvide>
        </React.StrictMode>
    </div>
  );
}

export default App;
