import React, { useContext, useEffect, useState } from "react";
import "../../Layouts/admin/Master.scss";

const Footer = () => { 
  const year = new Date().getFullYear();
  return (
    <footer className="py-2 bg-light mt-auto footer ">
      <div className="container-fluid px-2">
        <div className="d-flex align-items-center justify-content-between small">
          <div className="text-muted">
            Copyright &copy; Lotus Logistics {year}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
