import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import "../auth/auth.css";
import "../auth/home.scss";
import icon from "../../../assets/frontend/icon.png";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Login = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loginInput, setlogin] = useState({
    email: "",
    password: "",
  });

  // ...................isButtonDisabled.........................
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // .......................showPassword..........................................
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // ........................handleInput.........................................
  const handleInput = (e) => {
    e.persist();
    setlogin({ ...loginInput, [e.target.name]: e.target.value });
  };

  // ............................validate......................................
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email ID is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email ID format !";
    }
    if (!values.password) {
      errors.password = "Password is required !";
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
    }
    return errors;
  };

  // ........................loginsubmit.......................................

  const loginsubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setFormErrors(validate(loginInput));
    setIsSubmit(true);
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };
    try {
      axios.get("/csrf-cookie").then((res) => {
        axios.post(`/auth/login`, data).then((res) => {
          if (res.data.status === true) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("id", res.data.user.id);
            localStorage.setItem("name", res.data.user.name);
            window.location = "/admin";
          } else if (res.data.status === false) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "warning",
            });
          }
        });
      });
    } catch (error) {
      swal({
        title: "Oops...",
        text: "Something Went Wrong !",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
    setlogin({
      email: "",
      password: "",
    });
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  };

  // .................................isSubmit..............................

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors]);

  return (
    <div className="login container">
      <div className="row justify-content-center gx-0">
        <div className="col-md-3">
          <img src={icon} className="profile-admin"></img>
        </div>
        <div className="col-md-6">
          <div className="card">
            <div className="card-body ">
              <form novalidate>
                <div className="form-group mb-3">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleInput}
                    value={loginInput.email}
                    className="form-control"
                    autoComplete="off"
                  />
                </div>
                <p>{formErrors.email}</p>
                <div className="form-group mb-3">
                  <label>Password</label>
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleInput}
                      value={loginInput.password}
                      className="form-control"
                      autoComplete="off"
                    />
                    <a
                      onClick={toggleShowPassword}
                      className="password-toggle-btn "
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityIcon />}
                    </a>
                  </div>
                </div>
                <p>{formErrors.password}</p>
                <div className="row butt">
                  <div className="form-group mb-3 col-md-12 ">
                    <button
                      onClick={loginsubmit}
                      disabled={isButtonDisabled}
                      className="btn btn-primary btn-sm "
                    >
                      {isButtonDisabled ? "Loading..." : "Login"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
